import { Cart, CartLine, SalesOrder } from "@msdyn365-commerce/retail-proxy";

/**
 * Merge duplicate cartlines for same product. Sums up quantity and prices to its own cartline.
 */
export function _mergeDuplicateCartlines(cartlines: CartLine[]): CartLine[] {
    return cartlines;

    // @ts-expect-error
    const mergedCartlines: CartLine[] = [];

    cartlines.forEach(cartline => {
        const existingCartline = mergedCartlines.find(cl => cl.ProductId === cartline.ProductId);

        if (existingCartline) {
            const quantity = (existingCartline.Quantity || 0) + (cartline.Quantity || 0);
            const netAmountWithoutTax = cartline.NetAmountWithoutTax
                ? (existingCartline.NetAmountWithoutTax || 0) + cartline.NetAmountWithoutTax
                : (existingCartline.NetAmountWithoutTax || 0) + (cartline.Price || 0) * (cartline.Quantity || 0);

            mergedCartlines[mergedCartlines.indexOf(existingCartline)] = {
                ...existingCartline,
                Quantity: quantity,
                NetAmountWithoutTax: netAmountWithoutTax
            };
        } else {
            mergedCartlines.push({ ...cartline });
        }
    });

    return mergedCartlines;
}

export function getTotalDeposit(cartOrSalesOrder: Cart | SalesOrder | undefined): number {
    if (!cartOrSalesOrder) {
        return 0;
    }

    const depositInHeader = cartOrSalesOrder.ChargeLines?.reduce((acc, chargeLine) => {
        return acc + (chargeLine.ChargeCode?.includes('Pant') ? chargeLine.CalculatedAmount || 0 : 0);
    }, 0) || 0;
    let depositInLines = 0;

    if ('CartLines' in cartOrSalesOrder) {
        depositInLines = cartOrSalesOrder.CartLines?.reduce((acc, line) => {
            const summed = line.ChargeLines?.reduce((acc, chargeLine) => {
                const sumDeposit = (chargeLine.ChargeCode?.includes('Pant') ? chargeLine.CalculatedAmount || 0 : 0);
                return acc + sumDeposit;
            }, 0) || 0;
            return acc + summed;
        }, 0) || 0;
    } else if ('SalesLines' in cartOrSalesOrder) {
        depositInLines = cartOrSalesOrder.SalesLines?.reduce((acc, line) => {
            const summed = line.ChargeLines?.reduce((acc, chargeLine) => {
                const sumDeposit = (chargeLine.ChargeCode?.includes('Pant') ? chargeLine.CalculatedAmount || 0 : 0);
                return acc + sumDeposit;
            }, 0) || 0;
            return acc + summed;
        }, 0) || 0;
    }

    return depositInHeader + depositInLines;
}

export interface IChargeSum {
    ChargeCode: string;
    Description: string;
    TotalCalculatedAmount: number;
}

export function getAllCharges(cartOrSalesOrder: Cart | SalesOrder | undefined): IChargeSum[] | undefined {
    if (!cartOrSalesOrder) {
        return undefined;
    }

    const charges: IChargeSum[] = [];

    cartOrSalesOrder?.ChargeLines?.forEach(chargeLine => {
        if (chargeLine.Description && chargeLine.CalculatedAmount) {
            const existingCharge = charges.find(c => c.Description === chargeLine.Description);

            if (existingCharge) {
                existingCharge.TotalCalculatedAmount += chargeLine.CalculatedAmount;
            } else {
                charges.push({
                    ChargeCode: chargeLine.ChargeCode || '',
                    Description: chargeLine.Description,
                    TotalCalculatedAmount: chargeLine.CalculatedAmount
                });
            }
        }
    });

    if ('CartLines' in cartOrSalesOrder) {
        cartOrSalesOrder.CartLines?.forEach(cartLine => {
            cartLine.ChargeLines?.forEach(chargeLine => {
                if (chargeLine.Description && chargeLine.CalculatedAmount) {
                    const existingCharge = charges.find(c => c.Description === chargeLine.Description);

                    if (existingCharge) {
                        existingCharge.TotalCalculatedAmount += chargeLine.CalculatedAmount;
                    } else {
                        charges.push({
                            ChargeCode: chargeLine.ChargeCode || '',
                            Description: chargeLine.Description,
                            TotalCalculatedAmount: chargeLine.CalculatedAmount
                        });
                    }
                }
            });
        });
    } else if ('SalesLines' in cartOrSalesOrder) {
        cartOrSalesOrder.SalesLines?.forEach(salesLine => {
            salesLine.ChargeLines?.forEach(chargeLine => {
                if (chargeLine.Description && chargeLine.CalculatedAmount) {
                    const existingCharge = charges.find(c => c.Description === chargeLine.Description);

                    if (existingCharge) {
                        existingCharge.TotalCalculatedAmount += chargeLine.CalculatedAmount;
                    } else {
                        charges.push({
                            ChargeCode: chargeLine.ChargeCode || '',
                            Description: chargeLine.Description,
                            TotalCalculatedAmount: chargeLine.CalculatedAmount
                        });
                    }
                }
            });
        });
    }

    return charges;
}